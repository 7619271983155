@import "app/styles/mixins/fonts.scss";

.list {
  display: flex;
  flex-direction: column;
  position: absolute;
  z-index: 10000;
}

.button {
  @include ubuntu-14();
  background-color: rgba(37, 37, 55, 1);
  padding: 12px;
  transform: translate(-100, 0);
  width: 100%;
  z-index: 10000;

  &:hover {
    background-color: #ffffff0d;
  }
}
