@import "app/styles/mixins/fonts.scss";

.container {
  background-color: rgba(37, 37, 55, 1);
  border-radius: 16px;
  padding: 16px 50px;
}

.title {
  @include ubuntu-14();
  color: rgba(93, 93, 108, 1);
  margin-bottom: 8px;
}

.input {
  @include ubuntu-14();
  width: 100%;
}

.label {
  background-color: rgba(25, 25, 43, 1);
  border-radius: 8px;
  display: flex;
  padding: 12px 16px;
}

.button {
  width: 200px;
}

.content {
  display: grid;
  gap: 16px;
  grid-template-columns: calc(100% - 200px) 1fr;
}
