.container {
  background-color: rgba(37, 37, 55, 1);
  border-radius: 8px;
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  justify-content: space-between;
  padding: 24px 31px;
}

.languageTabs {
  display: flex;
  gap: 8px;
  width: 100%;
}

.tabButton {
  background: rgba(66, 66, 79, 1);
  border: none;
  border-radius: 8px;
  color: rgba(200, 200, 200, 1);
  cursor: pointer;
  font-size: 14px;
  padding: 8px 16px;
  transition: background-color 0.3s;

  &:hover {
    background: rgba(80, 80, 95, 1);
  }
}

.activeTab {
  background: rgba(100, 100, 120, 1);
  color: rgba(255, 255, 255, 1);
}

.select {
  background-color: rgba(255, 255, 255, 0.05);
  width: 100%;
}

.input {
  span {
    pointer-events: none;
  }
}

.top_content {
  display: grid;
  gap: 8px;
  grid-template-columns: 248px 192px 192px;
  width: 100%;
}

.language_content {
  display: grid;
  gap: 8px;
  grid-template-columns: 1fr;
  width: 100%;
}

.two_items_content {
  display: grid;
  gap: 8px;
  grid-template-columns: 1fr 1fr;
  width: 100%;
}

.three_items_content {
  display: grid;
  gap: 8px;
  grid-template-columns: 248px 192px 192px;
  width: 100%;
}

.four_items_content {
  display: grid;
  gap: 8px;
  grid-template-columns: repeat(3, 152px) 168px;
  margin-bottom: 16px;
  width: 100%;
}

.bottom_container {
  display: grid;
  gap: 8px;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  margin-top: 16px;
  position: relative;
  width: 100%;

  &::before {
    background-color: rgba(66, 66, 79, 1);
    content: "";
    height: 1px;
    position: absolute;
    top: -20px;
    width: 100%;
  }
}

.divider {
  width: 100%;
  height: 1px;
  background-color: rgba(66, 66, 79, 1);
  margin: 12px 0;
}

.validError {
  font-size: 10px;
  margin-top: 5px;
  color: var(--main-color4);
}

.add_top_margin > *:first-child {
  margin-top: 12px !important;
}
