.button_container {
  bottom: 12px;
  display: flex;
  gap: 3px;
  left: 15px;
  position: absolute;
  width: calc(100% - 30px);
  z-index: 3;
}

.hover_content {
  align-items: center;
  background-color: rgba(21, 23, 39, 0.7);
  border-radius: 8px;
  display: flex;
  gap: 7px;
  height: 100%;
  justify-content: center;
  left: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  transition: 0.5s;
  visibility: hidden;
  width: 100%;
}

.button {
  background: rgba(255, 255, 255, 0.3);
  border-radius: 12px;
  height: 2px;
  width: 100%;
}

.image {
  border-radius: 8px;
  object-fit: cover;
  width: 100%;
}

.button_active {
  background: #ffffff;
}

.blur {
  filter: blur(5px);
}

.badge {
  color: #18182a;
  left: 15px;
  position: absolute;
  text-transform: uppercase;
  top: 8px;
  z-index: 2;
}

.buy_button {
  width: 176px;
}

.image_wrapper {
  border-radius: 8px;
  display: flex;
  overflow: hidden;
  position: relative;

  &:hover {
    .hover_content {
      cursor: pointer;
      opacity: 1;
      transition: 0.5s;
      visibility: visible;
    }
  }
}

.enter_coitaner {
  display: flex;
  height: 100%;
  position: absolute;
  width: 100%;
}

.enter_container_item {
  width: 100%;
}

.list {
  display: grid;
  width: 100%;

  @media (max-width: 1064px) {
    transition: 1s;
  }
}

.item {
  @media (max-width: 1064px) {
    transition: 1s;
  }
}
