@import "../../app/styles/mixins/fonts.scss";

.button {
  box-shadow: 0 0 63px 0 #e6c04e75;
  margin-left: auto;
  margin-top: 25px;
  max-width: 200px;

  &:disabled {
    box-shadow: none;
    color: rgba(255, 255, 255, 0.05);

    &::before {
      background: rgba(255, 255, 255, 0.05);
    }
  }
}

.add_photo {
  @include ubuntu-medium-14();
  background-color: rgba(255, 255, 255, 0.05);
  border-radius: 8px;
  display: flex;
  gap: 11px;
  max-width: 233px;
  padding: 100px 39px;

  g path {
    stroke: currentColor;
  }
}
