@import "src/app/styles/mixins/fonts.scss";

.header {
  background-color: #252537;
  display: flex;
  gap: 24px;
  margin-bottom: 1px;
  padding: 10px 19px;
}

.info {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.name {
  @include ubuntu-medium-14();
}

.dialogs {
  @include ubuntu-11();
  color: rgba(124, 124, 146, 1);
}

.button {
  margin-left: auto;
}

.exit {
  position: relative;
}
