@import "../../../app/styles/mixins/fonts.scss";
@import "../../../app/styles/mixins/utility.scss";

.container {
  position: relative;
  width: 100%;
}

.button {
  @include ubuntu-14();
  background: #313140;
  border-radius: 8px;
  color: rgba(93, 93, 108, 1);
  padding: 12px 14px;
  position: relative;
  text-align: left;
  width: 100%;
}

.icon {
  position: absolute;
  right: 15px;
  @include vertical-center();
}

.active {
  color: #ffffff;
}

.dropdown {
  max-width: max-content;
  position: absolute;
  top: 45px;
  z-index: 100;
}

.error_text {
  color: rgb(255, 95, 95);
}

.error {
  border: 1px solid rgb(255, 95, 95);
  border-radius: 8px;
}
