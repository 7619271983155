@import "src/app/styles/mixins/fonts.scss";

.comments_button {
  @include ubuntu-14();
  align-items: center;
  border-radius: 16px;
  color: rgba(93, 93, 108, 1);
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  padding: 0 25px 15px;
  position: relative;
  text-align: start;
  transition: 1s;
  width: 100%;

  &::before {
    background-color: rgb(66, 66, 79);
    content: "";
    height: 1px;
    left: 50%;
    position: absolute;
    top: -10px;
    transform: translateX(-50%);
    width: calc(100% - 50px);
  }
}

.arrow {
  rotate: 0deg;
  transition: 0.5s;
}

.active {
  rotate: -180deg;
  transition: 0.5s;
}
