.item {
  position: relative;
}

.image {
  border-radius: 8px;
  height: 92px;
  object-fit: cover;
  width: 88px;
}

.trash_icon {
  background: rgba(252, 139, 132, 1);
  border-radius: 6px;
  cursor: pointer;
  padding: 5px;
  position: absolute;
  right: 5px;
  top: 5px;
}
