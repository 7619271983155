.list {
  display: flex;
  gap: 8px;
}

.badge {
  background-color: rgba(255, 255, 255, 0.05);
  border-radius: 16px;
}

.active {
  background: #42424f;
}
