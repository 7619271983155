@import "../../app/styles/mixins/fonts.scss";

.btn {
  margin-left: auto;
  margin-top: 40px;
  padding: 12px 48px;
  width: max-content;
}

///////
