@import "src/app/styles/mixins/fonts.scss";
@import "src/app/styles/mixins/utility.scss";

.container {
  align-items: flex-end;
  display: flex;
  gap: 9px;
}

.message_block {
  @include message-comments();
  background-color: rgba(37, 37, 55, 1);
  margin-right: 20px;

  @media (max-width: 1037px) {
    @include message-comments-mobile();
  }

  &::before {
    bottom: -3px;
    content: "";
    left: -8px;
    position: absolute;
  }
}

.time {
  @include ubuntu-11();
  color: rgba(124, 124, 146, 1);
}

.message {
  @include ubuntu-14();
  max-width: 345px;
}

.name {
  @include ubuntu-12();
  color: rgba(93, 93, 108, 1);
}

.user_block {
  display: flex;
  flex-direction: column;
  gap: 5px;
}
