@import "src/app/styles/mixins/fonts.scss";
@import "src/app/styles/mixins/breakpoints.scss";

.container {
  background-color: rgba(37, 37, 55, 1);
  border-radius: 16px;
  display: flex;
  flex-direction: column;
}

.text {
  @include ubuntu-14();
  line-height: 20px;
  max-width: 586px;
  padding: 0 25px;
}

.image {
  background-color: rgba(66, 66, 79, 1);
  height: 304px;
  margin-bottom: 15px;
  width: 100%;
}

.list {
  background-color: rgba(37, 37, 55, 1);
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(21, 23, 39, 0.8);
  display: flex;
  flex-direction: column;
  opacity: 1;
  position: absolute;
  z-index: 1000;
}

.button {
  @include ubuntu-14();
  background-color: rgba(37, 37, 55, 1);
  padding: 11px 15px;
  text-align: start;
  width: 100%;

  &:hover {
    background-color: rgba(255, 255, 255, 0.05);
  }
}

.images {
  padding: 19px 25px 0;
}

.dop {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
  padding: 0 25px;
}

.time {
  @include ubuntu-14();
  color: rgba(93, 93, 108, 1);

  @media (max-width: 1037px) {
    @include ubuntu-12();
    color: rgba(93, 93, 108, 1);
  }
}

.image {
  height: 336px;
  object-fit: cover;
  width: 100%;
}
