@import "../../../app/styles/mixins/fonts.scss";

.banner {
  align-items: center;
  background-color: rgba(37, 37, 55, 1);
  border-radius: 16px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 25px;
  padding: 12px 32px 12px 49px;
}

.banner_left_content {
  display: flex;
  gap: 16px;
}

.button {
  gap: 14px;
  max-width: 192px ();
}

.title {
  @include ubuntu-medium-20();
}
