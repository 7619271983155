@import "../../../app/styles/mixins/fonts.scss";

.list {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

.item {
  height: 176px;
  position: relative;
  width: 172px;
}

.image {
  border-radius: 10px;
  height: 100%;
  object-fit: cover;
  transition: 1s;
  width: 100%;
}

.delete {
  position: absolute;
  right: 9px;
  top: 9px;
  transition: 1s;

  path {
    stroke: #ffffff;
    transition: 0.3s;
  }

  &:hover {
    path {
      stroke: var(--main-color2);
    }
  }
}
