@import "src/app/styles/mixins/fonts.scss";
@import "src/app/styles/mixins/utility.scss";

.container {
  @include message-comments();
  background-color: rgba(66, 66, 79, 1);
  margin-left: 50px;

  @media (max-width: 1037px) {
    @include message-comments-mobile();
  }

  &::after {
    bottom: -3px;
    content: "";
    position: absolute;
    right: -8px;
  }
}

.time {
  @include ubuntu-11();
  color: rgba(124, 124, 146, 1);
}

.message {
  @include ubuntu-14();
}
