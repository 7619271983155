@import "src/app/styles/mixins/fonts.scss";

.container {
  background-color: rgba(37, 37, 55, 1);
  border-radius: 16px;
  padding: 23px 26px;
}

.textarea {
  @include ubuntu-14();
  border-radius: 6px;
  overflow: hidden;
  padding: 9px 16px;
  resize: none;
  width: 100%;
}

.buttons_container {
  display: grid;
  gap: 8px;
  grid-template-columns: 156px 1fr 176px;
}

.upload {
  align-items: center;
  display: flex;
  justify-content: flex-start;
}
