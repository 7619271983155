@import "src/app/styles/mixins/fonts.scss";

.button {
  align-items: center;
  background-color: initial;
  display: flex;
  gap: 14px;
  width: 100%;
}

.info {
  display: flex;
  flex-direction: column;
  gap: 5px;
  justify-content: flex-start;
}

.name {
  @include ubuntu-medium-14();
  text-align: start;
}

.dialogs {
  @include ubuntu-11();
  color: rgba(124, 124, 146, 1);
  text-align: start;
}

.buttons {
  display: grid;
  gap: 5px;
  grid-template-columns: 1fr 1fr;
  margin-left: auto;
}

.icon_active {
  path {
    stroke: rgba(230, 192, 78, 1);
  }
}

.time {
  @include ubuntu-11();
  color: rgba(124, 124, 146, 1);
}

.unread_message {
  @include ubuntu-8();
  background-color: rgba(230, 192, 78, 1);
  border-radius: 4px;
  color: rgba(21, 23, 39, 1);
  height: max-content;
  margin-left: auto;
  padding: 2px 5px;
  text-align: center;
  width: max-content;
}
