@import "../../../../app/styles/mixins/fonts.scss";

.card {
  backdrop-filter: blur(5px);
  background: #252537;
  border-radius: 16px;
  display: grid;
  gap: 45px;
  grid-template-columns: 256px repeat(2, 1fr);
  max-height: 256px;
  padding: 0 40px 0 0;
  position: relative;

  @media (max-width: 1230px) {
    display: flex;
    flex-direction: column;
    gap: 15px;
    max-height: max-content;
    padding: 15px;
  }
}

.price_container {
  padding: 24px 0;
  width: 100%;
  @media (max-width: 1230px) {
    padding: 0;
  }
}

.image {
  height: 256px;

  //   width: 100%;
}

.badge {
  color: #18182a;
}

.red {
  .background {
    background: rgba(254, 236, 144, 1);
  }

  .text_color {
    color: rgba(254, 236, 144, 1);
  }
}

.default {
  .background {
    background: #ffffff;
  }

  .text_color {
    color: #ffffff;
  }
}

.yellow {
  .background {
    background: #e6c04e;
  }

  .text_color {
    color: #e6c04e;
  }
}

.informations {
  display: flex;
  flex-direction: column;
  max-width: 381px;
  padding: 29px 0;

  @media (max-width: 1230px) {
    max-width: max-content;
    padding: 0;
  }
}

.winner {
  @include ubuntu-medium-14();
  color: rgba(230, 192, 78, 1);
}

.members_info {
  display: flex;
  justify-content: space-between;
}

.title {
  @include ubuntu-medium-20();
  align-items: center;
  display: flex;
  gap: 5px;
  margin-bottom: 10px;
}

.description {
  @include ubuntu-14();
  color: #5d5d6c;
  min-width: 381px;
}

.members {
  @include ubuntu-medium-14();
  color: rgba(230, 192, 78, 1);
  display: flex;
}

.header {
  display: flex;
  justify-content: space-between;
  margin-top: 11px;
}

.timer {
  margin-top: auto;
  position: relative;

  &::before {
    background: #42424f;
    bottom: 0;
    content: "";
    height: 1px;
    position: absolute;
    width: 100%;
  }
}

.timer_text {
  @include ubuntu-medium-14();
  align-items: flex-end;
  display: flex;
  margin-bottom: 10px;
}

.progress_bar {
  background: #ffffff;
  display: flex;
  height: 3px;
  position: relative;
  width: 60%;
}

.transactions {
  margin-top: 28px;
  position: relative;

  &::before {
    background-color: rgba(66, 66, 79, 1);
    content: "";
    height: 1px;
    position: absolute;
    top: -15px;
    width: 100%;
  }
}

.bussiness_buttons {
  margin-top: 18px;
}

.icon_crown {
  margin-left: 8px;
}

.conducted {
  display: flex;
  gap: 5px;
}
