@import "app/styles/mixins/fonts.scss";

.loginContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.loginForm {
  background: transparent;
  padding: 20px;
  border-radius: 8px;
  max-width: 400px;
  width: 100%;
}

.loginForm input {
  width: 100%;
  padding: 10px;
  margin-bottom: 15px;
  border-radius: 8px;
  border: 1px solid #ccc;
  color: #ccc;
}

.errorMessage {
  color: red;
  margin-bottom: 15px;
  text-align: center;
}

.fieldError {
  color: red;
  font-size: 14px;
  margin-top: -10px;
  margin-bottom: 15px;
  text-align: left;
}
