@import "../../app/styles/mixins/fonts.scss";

.title {
  @include ubuntu-medium-20();
}

.dropzone {
  align-items: center;
  background-color: rgba(255, 255, 255, 0.05);
  border-radius: 8px;
  box-sizing: border-box;
  cursor: pointer;
  justify-content: center;
  max-width: 296px;
}

.input_container {
  align-items: center;
  display: flex;
  gap: 9px;
  margin-top: 11px;
}

.dollar {
  @include ubuntu-medium-16();
}

.dropzone_container {
  display: grid;
  margin-top: 11px;
  position: relative;
}

.icon {
  margin: 0 auto;
  margin-bottom: 5px;

  g path {
    stroke: rgba(93, 93, 108, 1);
  }
}

.dropzone_text {
  @include ubuntu-14();
  color: rgba(93, 93, 108, 1);
  text-align: center;
}

.hidden {
  height: 0;
  line-height: 0;
  margin: 0;
  opacity: 0;
  overflow: hidden;
  padding: 0;
  width: 0;
}

.button_container {
  display: flex;
  gap: 8px;
  margin-top: 16px;
}

.with_images {
  display: flex;
  flex-direction: row;
  padding: 24px;

  svg {
    width: 30px;
  }
}

.without_images {
  display: flex;
  flex-direction: column;
  padding: 24px;
}

.loading_container {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
