.errorPage {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 60vh;
  text-align: center;
}

.errorPage h1 {
  font-size: 2rem;
  color: #ff4d4d;
  margin-bottom: 1rem;
}

.errorPage p {
  font-size: 1.5rem;
  color: #555;
}
