@import "app/styles/mixins/breakpoints.scss";

.box {
  display: flex;
  flex-direction: column;
  height: 680px;
  max-height: 680px;
  overflow: hidden auto;

  @include mobile() {
    background-color: transparent;
    height: calc(100dvh - 80px - 53px - 80px);
  }
}

.image {
  height: 42px;
  width: 42px;
}

.item {
  cursor: pointer;
  padding: 10px 9px;

  &:hover {
    background-color: rgba(255, 255, 255, 0.05);
  }
}

.list {
  background-color: #252537;
  height: 100%;
}
