@import "../../../app/styles/mixins/breakpoints.scss";

.container {
  bottom: 40px;
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 15px;
  position: fixed;
  right: 35px;
  z-index: 4;

  @include mobile() {
    bottom: 80px;
    padding: 15;
    right: 0;
    width: 100%;
  }
}
