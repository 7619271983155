.container {
  display: grid;
  gap: 16px;
  grid-template-columns: 1fr max-content;
}

.posts {
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-top: 15px;
}
