.chekbox {
  cursor: pointer;
  transition: 0.3s;

  g {
    stroke: #42424f;

    path:nth-child(2) {
      opacity: 0;
    }

    path:nth-child(1) {
      stroke: rgba(66, 66, 79, 1);
    }
  }

  &.checked {
    g {
      stroke: #feec90;

      path:nth-child(2) {
        opacity: 1;
      }

      path:nth-child(1) {
        stroke: #feec90;
      }
    }
  }
}
