.header {
  background-color: #252537;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 10;
  padding: 10px 20px;
  button {
    width: fit-content;
  }
}

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.brand {
  font-size: 24px;
  font-weight: bold;
}

.logoutButton {
  background-color: #dc3545;
  color: white;
  border: none;
  padding: 10px 15px;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s;
}

.logoutButton:hover {
  background-color: #c82333;
}
