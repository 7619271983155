@import "app/styles/mixins/fonts.scss";

.item {
  align-items: center;
  background-color: rgba(37, 37, 55, 1);
  border-radius: 8px;
  display: grid;
  gap: 15px;
  grid-template-columns: 1fr 1fr 120px 184px;
  padding: 8px 49px;
}

.name {
  @include ubuntu-14();
}

.button {
  @include ubuntu-12();
  color: rgba(255, 95, 95, 1);

  &:disabled {
    color: rgba(66, 66, 79, 1);
  }
}
