@import "../../../app/styles/mixins/fonts.scss";

.badge {
  @include ubuntu-14();
  background: #42424f;
  border-radius: 8px;
  padding: 2px 8px;
}

.badge-peach {
  background: linear-gradient(272deg, #b89054 -3.85%, #e7bb79 59.4%, #a87e4e 100.65%);
}

.badge-silver {
  background: linear-gradient(272deg, #818181 -3.85%, #d9d9d9 59.4%, #ababab 100.65%);
}

.badge-lemon_chiffon {
  background: linear-gradient(272deg, #e8c251 -3.85%, #fae384 59.4%, #edc35b 100.65%);
}

.badge-dusty_rose {
  background: linear-gradient(272deg, #958078 -3.85%, #f1dccc 59.4%, #b19681 100.65%);
}

.badge-coral {
  background: linear-gradient(90deg, #d68696 0%, #dd89a2 53.12%, #bc5976 100%);
}

.badge-camel {
  background: linear-gradient(90deg, #ad8e6a 0%, #caac88 52.08%, #866a48 100%);
}

.badge-purple {
  background: linear-gradient(261deg, #842be1 10.77%, #af7eff 89.93%);
}

.badge-mauve_blush {
  background: linear-gradient(272deg, #b07d9d -3.85%, #dfb4cc 59.4%, #9c6a89 100.65%);
}

.badge-midnight_navy {
  background: linear-gradient(272deg, #223b6e -3.85%, #546ea1 59.4%, #1a2a4f 100.65%);
}

.badge-sunset_flame {
  background: linear-gradient(272deg, #d35627 -3.85%, #ff9966 59.4%, #b04015 100.65%);
}

.badge-red {
  background: #fc8b84;
}
