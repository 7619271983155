@import "src/app/styles/mixins/fonts.scss";

.title {
  @include ubuntu-medium-20();
}

.text {
  @include ubuntu-14();
  color: rgba(255, 255, 255, 0.5);
  margin-top: 4px;
  max-width: 275px;
}

.buttons_container {
  display: flex;
  gap: 8px;
  margin-top: 9px;
}

.button {
  width: 151px;
}
