@import "../../../app/styles/mixins/fonts.scss";

.title {
  @include ubuntu-medium-20();
}

.date {
  @include ubuntu-14();
  color: rgba(93, 93, 108, 1);

  &:first-child {
    margin-right: 5px;
  }
}

.list {
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-top: 22px;
}

.sum {
  color: rgba(254, 236, 144, 1);
  text-align: end;
}

.item {
  align-items: center;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 2fr;
}

.nickname {
  @include ubuntu-14();
  margin-left: 5px;
}
