@import "../../../app/styles/mixins/fonts.scss";

.inputs_list {
  display: grid;
  gap: 8px;
  padding-left: 15px;
}

.first_row {
  display: grid;
  gap: 8px;
  grid-template-columns: 377px 232px 232px;
}

.textarea {
  @include ubuntu-14();
  background-color: rgba(255, 255, 255, 0.05);
  border-radius: 8px;
  margin-left: 15px;
  margin-top: 8px;
  min-height: 104px;
  padding: 12px 13px;
  resize: none;
  width: calc(100% - 16px);

  &::placeholder {
    color: rgba(93, 93, 108, 1);
  }
}

.error {
  border: 1px solid rgb(255, 95, 95);
  border-radius: 8px;
  color: red;

  &::placeholder {
    color: rgb(255, 95, 95);
  }
}

.second_row {
  display: grid;
  gap: 8px;
  grid-template-columns: 273px 272px 296px;
}

.content {
  background-color: rgba(37, 37, 55, 1);
  border: 1px solid rgba(93, 93, 108, 1);
  border-radius: 16px;
  padding: 17px 49px;
}

.header {
  display: flex;
  gap: 15px;
  margin-bottom: 25px;
}

.title {
  @include ubuntu-medium-20();
}
