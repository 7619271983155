@import "../../../app/styles/mixins/fonts.scss";

.image_container {
  display: flex;
  flex-direction: column;
  gap: 5px;
  min-width: 257px;
  max-width: 257px;
}

.add_button {
  display: flex;
  gap: 8px;

  svg g path {
    stroke: currentColor;
  }
}

.input {
  background: rgba(255, 255, 255, 0.05);
  margin-top: 3px;

  span {
    pointer-events: none;
    left: 11px !important;
  }
}

.image {
  position: relative;
}

.delete {
  position: absolute;
  right: 16px;
  top: 16px;
}

.placeholder_image {
  background-color: rgba(37, 37, 55, 1);
  border-radius: 8px;
  height: 360px;
  margin-bottom: 3px;
  width: 257px;
}

.validError {
  font-size: 10px;
  color: var(--main-color4);
}
