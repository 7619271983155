.badge {
  background-color: #252537;
  color: #5d5d6c;
  text-wrap: nowrap;
}

.badges {
  align-items: center;
  display: flex;
  gap: 8px;
  overflow-x: auto;
  padding: 10px 0;

  &::-webkit-scrollbar {
    background-color: #252537;
    border-radius: 5px;
    height: 5px;
    width: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background: #e8edf0;
    border-radius: 5px;
    height: 5px;
    width: 5px;
  }
}

.active_badge {
  background-color: rgba(230, 192, 78, 1);
  color: #000000;
}

.item {
  text-wrap: nowrap;
}
