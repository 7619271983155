.selects {
  display: grid;
  gap: 7px;
  overflow-x: auto;
  padding: 0 0 6px;
}

.icon {
  margin: 4px 0 0 5px;
}
