@import "app/styles/mixins/fonts.scss";

.button {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 4px;
  justify-content: center;
  padding: 10px 16px;
  width: 100%;
}

.item {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.icon {
  align-items: center;
  background-color: rgba(93, 93, 108, 1);
  border-radius: 24px;
  display: flex;
  justify-content: center;
  padding: 10px;

  svg path {
    stroke: #ffffff;
  }
}

.list {
  background-color: rgba(37, 37, 55, 1);
  border-right: 1px solid inherit;
}

.text {
  @include ubuntu-14();
}
