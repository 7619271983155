@import "src/app/styles/mixins/fonts.scss";
@import "src/app/styles/mixins/breakpoints.scss";

.container {
  align-items: center;
  background-color: rgba(37, 37, 55, 1);
  border-radius: 16px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
  padding: 12px 48px;

  @media (max-width: 1037px) {
    padding: 0;
  }
}

.title {
  @include ubuntu-medium-20();

  @media (max-width: 1037px) {
    display: none;
  }
}

.input {
  height: 32px;
  min-width: 352px;
  @media (max-width: 1037px) {
    min-width: auto;
    width: 100%;
  }
}
