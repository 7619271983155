@import "../../../app/styles/mixins/fonts.scss";

.container {
  position: relative;
  width: 100%;
  border-radius: 8px;

  &.error {
    .color_text {
      color: var(--main-color4);
    }
  }
}

.button {
  @include ubuntu-14();
  align-items: center;
  background: #313140;
  border-radius: 8px;
  color: rgb(93, 93, 108);
  display: flex;
  height: 40px;
  padding: 13px 50px 14px 12px;
  width: 100%;
}

.color_block {
  border-radius: 8px;
  height: 24px;
  width: 100%;
  z-index: 100;
}

.color_text {
  margin-top: 4px;
  text-align: left;
}

.select_list {
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin-top: 15px;
  position: absolute;
  width: 100%;
  background: #313140;
  border-radius: 5px;
  z-index: 100;
}

.select_item {
  align-items: center;
  background: #313140;
  border-radius: 5px;
  display: flex;
  height: 40px;
  padding: 8px;
}

.select_item:hover {
  background-color: rgba(37, 37, 55, 1);
  border-radius: 0;
}

.select_item:hover:first-child {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.select_item:hover:last-child {
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.item_button {
  border-radius: 8px;
  height: 24px;
  padding: 5px;
  width: 100%;
}

.error {
  border: 1px solid rgba(255, 95, 95, 1);
}
