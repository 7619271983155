@import "app/styles/mixins/fonts.scss";

.container {
  align-items: center;
  background-color: rgba(255, 255, 255, 0.05);
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
  padding: 5px 16px;
}

.text {
  @include ubuntu-11();
  color: rgba(107, 107, 125, 1);
}

.value {
  @include ubuntu-11();
}
