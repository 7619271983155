@import "src/app/styles/mixins/fonts.scss";
@import "src/app/styles/mixins/utility.scss";

.container {
  background-color: rgba(21, 23, 39, 1);
  border-radius: 16px 16px 0 0;
  max-height: 360px;
  min-height: 360px;
  overflow-y: auto;
  padding: 8px 15px;

  @media (max-width: 1037px) {
    border-radius: inherit;
    overflow: hidden auto;
    padding: 8px 0;
    padding-right: 10px;
  }

  @include scrollbar();
}

.list {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.another_user {
  display: flex;
  justify-content: flex-start;
}

.user {
  display: flex;
  justify-content: flex-end;
}

.title {
  @include ubuntu-12();
  background-color: rgba(37, 37, 55, 1);
  border-radius: 16px;
  color: rgba(93, 93, 108, 1);
  margin: 0 auto 8px;
  padding: 5px 15px;
  width: max-content;
}
