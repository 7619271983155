@mixin vertical-center {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

@mixin appearances {
  animation: fadeInUp 1s forwards;
  opacity: 0;

  @keyframes fadeInUp {
    0% {
      opacity: 0;
      transform: translateY(20px);
    }

    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
}

@mixin message-comments-mobile {
  max-width: 279px;
  min-width: 236px;
  padding: 11px 14px;
  width: 100%;
}

@mixin message-comments {
  align-items: flex-end;
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  max-width: 367px;
  min-width: 263px;
  padding: 11px 24px;
  position: relative;
  width: max-content;
}

@mixin full-center {
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
}

@mixin scrollbar {
  &::-webkit-scrollbar {
    background-color: #252537;
    border-radius: 5px;
    height: 5px;
    width: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background: #e8edf0;
    border-radius: 5px;
    height: 5px;
    width: 5px;
  }
}
