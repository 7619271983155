@import "../../app/styles/mixins/fonts.scss";

.content {
  display: flex;
  gap: 16px;
}

.filters {
  background-color: rgba(37, 37, 55, 1);
  height: 250px;
  width: 100%;
}

.info_container {
  width: 100%;
}

.tabs {
  margin-top: 20px;
}
