@import "app/styles/mixins/fonts.scss";

.container {
  border-radius: 8px;
  display: flex;
  position: relative;
  z-index: 1;

  &:not(.multilines) {
    height: 40px;
  }

  .tip {
    @include ubuntu-10();
    left: 24px;
    opacity: 0;
    position: absolute;
    top: 3px;
    transition: 0.3s;
  }

  &:focus-within {
    .tip {
      opacity: 1;
    }
  }

  .input {
    @include ubuntu-medium-14();
    flex: 2;
    min-width: 0;
    padding: 15px 15px 15px 24px;
    resize: none;

    &::placeholder {
      @include ubuntu-14();
    }
  }

  .icon-right-container {
    align-items: center;
    background: transparent;
    display: inline-flex;
    flex: 0;
    flex-shrink: 0;
    padding: 15px 15px 15px 0;
  }

  &.input-container1 {
    background: var(--btn1-bg);
    height: 48px;

    .input {
      &::placeholder {
        color: var(--main-color3);
      }
    }
  }

  &.input-container2 {
    background: #313140;

    .input {
      @include ubuntu-14();
      height: max-content;
      margin-top: auto;
      padding: 6px 14px;
    }

    .tip {
      color: #5d5d6c;
      left: 14px;
      opacity: 1;
      top: 6px;

      &.tip-placeholder {
        font-size: 14px;
        top: 12px;
      }
    }

    &.error {
      .tip {
        color: var(--main-color4);
      }
    }

    &.success {
      .tip {
        color: var(--main-color2);
      }
    }
  }

  &.input-container3 {
    background: #313140;

    .input {
      @include ubuntu-14();
      height: max-content;
      margin-top: auto;
      padding: 6px 3px;
    }

    .tip {
      color: #5d5d6c;
      left: 14px;
      opacity: 1;
      top: 6px;

      &.tip-placeholder {
        font-size: 14px;
        top: 12px;
      }
    }

    &.error {
      .tip {
        color: var(--main-color4);
      }
    }

    &.success {
      .tip {
        color: var(--main-color2);
      }
    }
  }

  // &.error {
  //     border: 1px solid red;
  // }

  // &.success {
  //     border: 1px solid green;
  // }
  .step-buttons-container {
    display: flex;
    gap: 10px;
    position: absolute;
    right: 12px;
    top: 12px;

    svg:hover {
      cursor: pointer;

      path {
        color: var(--main-color2);
      }
    }
  }
}

.error {
  border: 1px solid rgba(255, 95, 95, 1);
}
