.list_upload_button {
  display: grid;
  gap: 25px;
  grid-template-columns: repeat(4, 1fr);
  margin-top: 25px;
}

.empty_photo {
  background-color: rgba(255, 255, 255, 0.02);
  border-radius: 8px;
  max-width: 233px;
  padding: 108px 53px;
}

.image {
  border-radius: 8px;
  height: 216px;
  object-fit: cover;
  width: 223px;
}

.image_item {
  position: relative;
}

.trash_button {
  background-color: rgb(66, 66, 79);
  border-radius: 8px;
  padding: 6px;
  position: absolute;
  right: 12px;
  top: 9px;
}
