@import "src/app/styles/mixins/fonts.scss";
@import "src/app/styles/mixins/breakpoints.scss";

.container {
  background-color: rgba(37, 37, 55, 1);
  border-radius: 16px;
  height: max-content;
  min-width: 296px;
  padding: 20px 24px;

  @media (max-width: 1037px) {
    display: none;
  }
}

.list_category {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.text {
  @include ubuntu-14();
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.selected_category {
  color: rgba(254, 236, 144, 1);
}

.subcategories_list {
  overflow: hidden;
  visibility: hidden;

  &.visible {
    max-height: 500px;
    overflow: visible;
    transition: 0.5s;
    visibility: visible;
  }

  &.hidden {
    max-height: 0;
    overflow: hidden;
    transition: 0.2s;
    visibility: hidden;
  }
}
