@import "src/app/styles/mixins/fonts.scss";
@import "src/app/styles/mixins/breakpoints.scss";

.list {
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding-left: 15px;
  transform: translateY(10px);
  transition: opacity 0.5s ease-in-out;
}

.text {
  @include ubuntu-14();
  color: rgba(93, 93, 108, 1);
}
