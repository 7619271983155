@import "src/app/styles/mixins/fonts.scss";

.input {
  background: rgba(255, 255, 255, 0.05);
  border-radius: 8px;
  color: rgba(255, 255, 255, 1);
  padding: 12px 14px;
}

.title {
  @include ubuntu-medium-20();
}

.input_container {
  align-items: center;
  display: flex;
  gap: 5px;
  margin-top: 15px;
}

.checkbox_text {
  @include ubuntu-14();
  color: rgba(255, 255, 255, 0.5);
}

.checkbox_container {
  align-items: center;
  display: flex;
  gap: 5px;
  margin-top: 10px;
}

.buttons_container {
  display: flex;
  gap: 8px;
  margin-top: 22px;
}

.checkbox svg g path {
  stroke: red;
}
