@import "src/app/styles/mixins/fonts.scss";

.textarea {
  @include ubuntu-14();
  overflow: hidden;
  padding: 14px 10px 5px 25px;
  resize: none;
  width: 90%;
}

.container {
  align-items: center;
  display: flex;
}
