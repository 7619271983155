@import "../../../../app/styles/mixins/breakpoints.scss";
@import "../../../../app/styles/mixins/fonts.scss";

.list {
  display: grid;
  gap: 16px;
  grid-template-columns: repeat(4, 1fr);
  margin-top: 15px;

  @include table() {
    grid-template-columns: repeat(3, 1fr);
  }

  @include medium() {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 705px) {
    grid-template-columns: repeat(1, 1fr);
  }
}

.quickFilters {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  justify-content: space-between;
  overflow: auto;
  //@include scrollbar();

  @media (max-width: 600px) {
    display: none;
  }
}

.reset_button {
  @include ubuntu-14();
  color: rgba(93, 93, 108, 1);
  display: flex;
  gap: 10px;

  svg path {
    stroke: rgba(93, 93, 108, 1);
  }
}

.pagination {
  margin-top: 35px;
}
