@import "../../../../app/styles/mixins/breakpoints.scss";

.toast {
  background: #252537;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  margin-left: auto;
  min-width: 300px;
  opacity: 0;
  padding: 25px;
  position: relative;
  transform: translateX(150px);
  transition: 1s;
  visibility: hidden;
  width: max-content;

  @include mobile() {
    width: 100%;
  }
}

.show {
  opacity: 1;
  transform: translateX(0);
  visibility: visible;
}

.hide {
  right: 0;
  transform: translateX(150px);
  visibility: hidden;
  z-index: -1;
}

.proggres_bar {
  background-color: #ffffff;
  height: 1px;
  margin-top: 10px;
}

.button {
  align-items: center;
  border-radius: 4px;
  display: flex;
  margin-left: auto;
  padding: 3px;
  position: absolute;
  right: 5px;
  top: 10px;
  width: 24px;

  svg {
    path {
      stroke: #ffffff;
    }
  }

  &:hover {
    border-color: rgba(255, 188, 73, 1);
    transition: 0.5s;

    svg {
      path {
        stroke: rgba(255, 188, 73, 1);
      }
    }
  }
}

.message {
  max-width: 350px;
}
