@import "../../../app/styles/mixins/fonts.scss";

.container {
  background-color: #252537;
  border-radius: 8px;

  // max-width: 300px;
  // min-width: 174px;
  position: relative;
}

.select_list {
  background-color: inherit;
  border-radius: 5px;
  list-style: none;
  margin: 0;
  max-height: 200px;
  overflow: hidden;
  overflow-y: auto;
  padding: 0;
  position: absolute;
  top: 55px;
  width: 100%;
  z-index: 3;

  &::-webkit-scrollbar {
    background-color: #252537;
    border-radius: 5px;
    width: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background: #e8edf0;
    border-radius: 5px;
    width: 5px;
  }
}

.select {
  text-align: left;
  width: 100%;
}

.button {
  @include ubuntu-14();
  background-color: #252537;
  border: none;
  border-radius: 8px;
  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  height: 40px;
  outline: none;
  padding: 12px 22px;
  text-align: left;
  width: 100%;
  word-wrap: break-word;
}

.list_button {
  border-radius: 0;

  &:hover {
    background-color: #42424f;
  }
}

.animation_icon {
  transition:
    0.5s ease-in-out,
    -webkit-transform 0.2s ease-in-out;
}

.select_open {
  .animation_icon {
    transform: rotate(180deg);
  }
}

.icon {
  margin-left: auto;
}

.active_button {
  background-color: #42424f;
  pointer-events: none;
}

.tip {
  font-size: 10px;
  left: 14px;
  position: absolute;
  top: 6px;

  &.tip-placeholder {
    font-size: 14px;
    top: 12px;
  }
}

.container.v2 {
  .tip {
    color: #5d5d6c;
    transition: 0.3s;
  }

  .button {
    align-items: flex-end;
    background-color: #313140;
    padding: 6px 14px;
  }

  &.error {
    .tip {
      color: var(--main-color4);
    }
  }

  &.success {
    .tip {
      color: var(--main-color2);
    }
  }
}

.select_list.v2 {
  .button {
    background-color: #313140;

    &:hover {
      background-color: #252537;
    }
  }

  .active_button {
    background-color: #252537;
  }
}

.container.v3 {
  .button {
    @include ubuntu-14();
    background-color: rgba(255, 255, 255, 0.05);
    color: rgba(93, 93, 108, 1);
  }
}

.select_list.v3 {
  .button {
    background-color: #313140;

    &:hover {
      background-color: #252537;
    }
  }

  .active_button {
    background-color: #252537;
  }
}

.error {
  border: 1px solid rgba(255, 95, 95, 1);
}
