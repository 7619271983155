@import "../../../app/styles/mixins/fonts.scss";

.pagination {
  display: flex;
  gap: 20px;
}

.list {
  align-items: center;
  display: flex;
  gap: 5px;
}

.button {
  @include ubuntu-11();
  padding: 9px 13px;

  &:disabled {
    cursor: unset;
  }
}

.active_button {
  backdrop-filter: blur(5px);
  background: rgba(255, 255, 255, 0.05);
  border-radius: 8px;
}

.item {
  height: max-content;
}

.multipoint {
  align-items: center;
  display: flex;
}
